<template>
  <div id="app" :style="{ backgroundImage: `url(${require('@/assets/original-background.jpg')})`, backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', backgroundSize: 'cover' }">
    <section class="section" style="margin-top: 50px;">
      <center>
        <div class="container">
          <div class="col-md-6">
            <p style="font-size: 13pt; color: black; font-weight: bold; letter-spacing: 6px; margin-bottom: 20px;">{{ code }}</p>
            <p style="color: black; font-weight: bold; line-height: 1.2; font-size: 12pt;">
              This is 100% GENUINE PRODUCT<br />
              Thank you for purchasing our genuine product.<br /><br />
              安全码认证<br />
              此产品是100%正品。<br />
              谢谢支持！<br />
              认证: {{ scanCount }}次
            </p>
            <img src="@/assets/original-logo.png" style="margin-top: 10px; width: 40%;" />
            <img src="@/assets/company-logo.jpg" style="margin-top: 25px; width: 70%;" />
            <br />
            <img src="@/assets/dermedical-skinsciences-logo.png" style="margin-top: 30px; margin-bottom: 10px; width: 50%;" />
            <br />
            <p style="font-size: 12px; font-weight: bold; color: #BD803C; line-height: 1.2; margin-bottom: 10px;">
              Via K. Marx, 18, Noverasco di Opera,<br />
              MI, 20090, Italy. Centralino: +39 010 386 527
            </p>
          </div>
        </div>
      </center>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: '',
      scanCount: 0,
      status: '', // 状态：valid, invalid, exceeded
      errorMessage: '', // 错误信息
    };
  },
  async created() {
    const code = window.location.pathname.substring(1);

    try {
      const response = await fetch(`/verify/${code}`);
      const data = await response.json();

      this.code = data.code;
      this.status = data.status;

      if (this.status === 'valid') {
        if (data.message === 'First visit') {
          this.scanCount = 1;
        } else if (data.message === 'Second visit') {
          this.scanCount = 2;
        }
      } else if (this.status === 'exceeded' || this.status === 'invalid') {
        this.errorMessage = data.message;
      }
    } catch (error) {
      console.error('Error:', error);
      this.errorMessage = 'An error occurred while verifying the code.';
    }
  },
  methods: {
    getErrorPage() {
      if (this.status === 'exceeded') {
        return 'error.html';
      } else if (this.status === 'invalid') {
        return 'invalid.html';
      }
      return null;
    },
  },
};
</script>

<style>
@import "@/assets/vendor/bootstrap/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.section {
  margin-top: 50px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

p {
  margin: 0;
  padding: 0;
}
</style>